export default class CostCenterConfig {
  public requireCostCenter: number

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.requireCostCenter = data.requireCostCenter ? (data.requireCostCenter as number) : 0
  }

  public clone(): CostCenterConfig {
    const result = new CostCenterConfig()
    Object.assign(result, this)
    return result
  }
}