import { axios } from '@/plugins/vueaxios'
import CostCenterConfig from '@/models/economy/CostCenterConfig'

export default class CostCenterService {
  public static loadOne(): Promise<CostCenterConfig> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/cost-center-config')
        .then((response) => {
          resolve(new CostCenterConfig(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static update(data: CostCenterConfig): Promise<CostCenterConfig> {
    return new Promise((resolve, reject) => {
      axios
        .put('/v4/site/economy/cost-center-config', data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}